import React, { useEffect, useRef } from 'react';
import Highcharts, { Chart } from 'highcharts/highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import { useTranslation } from 'react-i18next';

import TargetIcon from '../../../assets/icons/target-icon.svg';

import { ProbabilityOfSuccessLegend } from './Legend';
import { GoalPriorityEnum } from '../../../common/types';

import styled from '@emotion/styled';

interface ExtendedChart extends Chart {
    targetIcon?: Highcharts.SVGElement;
    group?: Highcharts.SVGElement;
    targetLabel?: Highcharts.SVGElement;
}

type Props = {
    value: number;
    target: number;
    thresholdValue: number;
    dangerValue: number;
    goalType: GoalPriorityEnum;
};

//to initialize
highchartsMore(Highcharts);
solidGauge(Highcharts);

export const ProbabilityOfSuccess = ({
    value = 0,
    target = 0,
    thresholdValue = 0,
    dangerValue = 0,
    goalType
}: Props) => {
    const chartComponentRef = useRef<HighchartsReactRefObject | null>(null);
    const { t } = useTranslation();

    const getColorByVariant = () => {
        if (value < dangerValue) return '#DC0546';
        if (value < thresholdValue) return '#FF6035';
        return '#00A096';
    };

    const color = getColorByVariant();

    useEffect(() => {
        const { chart }: { chart?: ExtendedChart } = chartComponentRef.current || {};

        if (chart) {
            const angle = 180 * (target / 100);
            const angleRadius = (angle * Math.PI) / 180;
            const centerX = chart.plotWidth * (140 / 340) + chart.plotLeft - 18;
            const centerY = chart.plotHeight / 2 + chart.plotTop + 18 - 40;
            const radius = 140 * 0.99;
            const xPosition = centerX - radius * Math.cos(angleRadius);
            const yPosition = centerY - radius * Math.sin(angleRadius);
            if (chart?.targetIcon) {
                //to add target icon on pie chart
                chart.targetIcon.attr({
                    x: xPosition,
                    y: yPosition,
                    zIndex: 5
                });
            } else {
                chart.targetIcon = chart.renderer
                    .image(TargetIcon, xPosition, yPosition, 36, 36)
                    .attr({ zIndex: 5 })
                    .add();
            }
            if (chart?.targetLabel) {
                //to add target label on pie chart
                chart.targetLabel.attr({
                    x: xPosition + 20,
                    y: yPosition - 5,
                    zIndex: 5
                });
            } else {
                chart.targetLabel = chart.renderer
                    .text('Target', xPosition + 20, yPosition - 5)
                    .attr({ zIndex: 5 })
                    .css({ fontSize: '16px', color: '#3769FF', fontWeight: '600' })
                    .add();
            }
            const existingGroup = chart.renderer.box.querySelector('.highcharts-groupMessage');
            if (existingGroup) {
                existingGroup.parentNode?.removeChild(existingGroup);
            }
            const group = chart.renderer
                .g('groupMessage') // to add pie chart below description
                .attr({ transform: 'translate(10, 200)' })
                .add();
            chart.renderer
                .text(t('PROBABILITY_OF_SUCCESS'), 55, 0)
                .css({
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '20px'
                })
                .add(group);

            chart.redraw(true);
        }
    }, [value, target, goalType, thresholdValue]);

    const options = {
        chart: {
            type: 'pie',
            plotBorderWidth: null,
            plotShadow: false,
            width: 340,
            height: 360,
            style: {
                // adding default font family for this highchart element. Need to check if we can  handle globally later.
                fontFamily: 'TT Commons Pro'
            }
        },
        title: {
            verticalAlign: 'middle',
            floating: true,
            text: `${value}%`,
            y: 18,
            x: -20,
            style: {
                fontSize: '48px',
                fontWeight: 600,
                lineHeight: '50px'
            }
        },
        tooltip: {
            enabled: false
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                innerSize: '96%',
                borderWidth: 6,
                borderColor: null,
                slicedOffset: 20,
                dataLabels: {
                    enabled: false
                },
                center: ['40%', '50%']
            }
        },
        series: [
            {
                name: 'probability',
                data: [
                    { name: 'value', y: value, color },
                    { name: 'gap', y: 1, color: 'white' },
                    { name: 'remaining', y: 100 - value - 1, color: '#eeeeee' }
                ],
                enableMouseTracking: false
            }
        ],
        credits: {
            enabled: false
        }
    };

    const isOnTarget = value >= thresholdValue;

    return (
        <Container>
            <ChartContainer>
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
            </ChartContainer>

            <div
                style={{
                    height: '235px'
                }}
            ></div>

            <ProbabilityOfSuccessLegend
                color={color}
                isOnTarget={isOnTarget}
                goalPriority={goalType}
                thresholdValue={thresholdValue}
            />
        </Container>
    );
};

const Container = styled('div')(() => ({
    position: 'relative',
    width: '340px'
}));

const ChartContainer = styled('div')(() => ({
    position: 'absolute',
    top: 0,
    left: 0
}));
