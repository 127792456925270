import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Icon } from '../../atoms/Icon/Icon';
import { PATPalette } from '../../../themes/palette';

const Background = styled('div')<{ width: string }>(({ width }) => ({
    width,
    maxWidth: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    background: 'rgba(191,191,191,0.8)',
    zIndex: 100,
    height: '100%',
    padding: '79px 23px'
}));

const BackgroundCentered = styled('div')<{ width: string }>(({ width }) => ({
    width,
    maxWidth: '100%',
    position: 'fixed',
    top: '40%',
    right: '40%',
    zIndex: 100,
    padding: '79px 23px'
}));

const Wrapper = styled('div')(() => ({
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
}));

const Card = styled('div')<{ cardMaxWidth: string }>(({ cardMaxWidth }) => ({
    display: 'flex',
    padding: '40px',
    flexDirection: 'column',
    background: PATPalette.neutral.white,
    position: 'relative',
    maxWidth: cardMaxWidth,
    margin: 'auto',
    '> #warning': {
        marginBottom: '24px',
        color: PATPalette.secondary.orange[300],
        width: '28px',
        height: '28px'
    }
}));

const CloseButton = styled('button')(() => ({
    display: 'flex',
    width: '48px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    position: 'absolute',
    top: 12,
    right: 12,
    padding: 0,
    margin: 0
}));

const Title = styled('p')(() => ({
    all: 'unset',
    color: PATPalette.neutral.black,
    fontFamily: 'TT Commons Pro',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    marginBottom: '8px'
}));

const Content = styled('p')(() => ({
    all: 'unset',
    color: PATPalette.neutral.grey[500],
    fontFamily: 'TT Commons Pro',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.16px',
    marginBottom: '18px'
}));

const ButtonsContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '29px'
}));

const Button = styled('button')<{ color: string }>(({ color }) => ({
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    color,
    fontFamily: 'TT Commons Pro',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
    textDecorationLine: 'underline',
    padding: 0,
    margin: 0,
    textUnderlineOffset: '4px'
}));

export interface ConfirmationDialogProps {
    open: boolean;
    content: string;
    title?: string;
    width?: string;
    cardMaxWidth?: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    onCancellation: () => void;
    onConfirmation: () => void;
}

export const ConfirmationDialog = ({
    content,
    confirmButtonLabel = 'Confirm',
    cancelButtonLabel = 'Cancel',
    onConfirmation,
    onCancellation,
    open = false,
    title,
    width = '400px',
    cardMaxWidth = '567px'
}: ConfirmationDialogProps) => {
    if (!open) {
        return null;
    }

    return (
        <Background width={width}>
            <ConfirmationContent
                cancelButtonLabel={cancelButtonLabel}
                confirmButtonLabel={confirmButtonLabel}
                content={content}
                onCancellation={onCancellation}
                onConfirmation={onConfirmation}
                open
                title={title}
                cardMaxWidth={cardMaxWidth}
            />
        </Background>
    );
};

export const ConfirmationCentered = ({
    content,
    confirmButtonLabel = 'Confirm',
    cancelButtonLabel = 'Cancel',
    onConfirmation,
    onCancellation,
    open = false,
    title,
    width = '400px'
}: ConfirmationDialogProps) => {
    if (!open) {
        return null;
    }

    return (
        <BackgroundCentered width={width}>
            <Wrapper>
                <ConfirmationContent
                    cancelButtonLabel={cancelButtonLabel}
                    confirmButtonLabel={confirmButtonLabel}
                    content={content}
                    onCancellation={onCancellation}
                    onConfirmation={onConfirmation}
                    open
                    title={title}
                />
            </Wrapper>
        </BackgroundCentered>
    );
};

const ConfirmationContent = ({
    content,
    confirmButtonLabel = 'Confirm',
    cancelButtonLabel = 'Cancel',
    onConfirmation,
    onCancellation,
    open = false,
    title,
    cardMaxWidth = '567px'
}: ConfirmationDialogProps) => {
    if (!open) {
        return null;
    }

    return (
        <Card cardMaxWidth={cardMaxWidth}>
            <CloseButton type="button" onClick={onConfirmation}>
                <CloseIcon color="inherit" />
            </CloseButton>

            <Icon id="warning" />

            {!!title && <Title>{title}</Title>}
            <Content>{content}</Content>

            <ButtonsContainer>
                <Button type="button" color={PATPalette.neutral.grey[400]} onClick={onCancellation}>
                    {cancelButtonLabel}
                </Button>

                <Button type="button" color={PATPalette.primary.skyBlue} onClick={onConfirmation}>
                    {confirmButtonLabel}
                </Button>
            </ButtonsContainer>
        </Card>
    );
};
